import React from "react"
import { Link } from "gatsby"
const Start = ({ data, location }) => {
  return (
    <>
      <section className="intro-agile">
        <section className="section-text-intro">
          <div className="text-intro-titleagile">
            <div className="btn-return return-agile">
              <Link
                to={`/nosotros/${location.search}`}
                className="link-to-return" aria-label="enlace"
              >
                <span className="flecha-izquierda">
                  <svg
                    width="16"
                    height="10"
                    viewBox="0 0 16 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 5L1 5M1 5L5.09091 9M1 5L5.09091 0.999999"
                      stroke="#2C5C82"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                Regresar
              </Link>
            </div>
          {data.title}
          </div>

          <p className="text-body-agile">{data.textbody} </p>
        </section>
        <section className="img-intro-agile">
          <img
            loading="lazy"
            className="photo-agile"
            src={data.image}
            alt="fotos"
          />
        </section>
      </section>
      <hr className="hr-agile" />
    </>
  )
}

export default Start
