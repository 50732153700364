import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg width="206" height="11" viewBox="0 0 206 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M38.8764 10.9239C34.6648 9.87281 3.76517 10.793 1.52197 10.4693C-0.308236 10.0436 2.67299 2.79774 4.38218 1.73977C4.98135 1.35935 5.67466 1.30834 7.26097 1.49449C9.77591 1.84111 42.3298 1.42224 42.3298 1.42224C42.758 1.64911 44.0527 0.902939 44.2876 1.17739C45.0286 1.81914 97.7925 1.32726 102.292 1.3243C105.02 1.32273 102.859 1.741 106.071 1.58899C112.915 1.28797 127.823 1.72977 133.01 1.368C137.161 1.0688 138.055 1.06118 142.036 1.35529C146.104 1.62204 167.744 1.43328 170.221 0.940323C170.828 0.80475 171.292 0.85702 171.291 1.01788C171.291 1.05285 186.543 1.28027 186.612 1.40666C186.725 1.56833 187.189 1.64856 187.62 1.53972C188.419 1.37754 204.658 0.884579 204.91 1.35498C205.698 2.7035 203.421 8.40864 201.525 9.78703C199.585 11.263 189.001 9.51608 180.478 10.3507L140.824 10.1595L132.321 10.6585C131.351 10.169 127.597 11.3593 125.34 10.476C124.386 10.1265 117.633 10.0295 116.139 10.3825C114.839 10.6531 80.2953 9.70085 76.7448 10.6478C70.4355 9.79157 44.8158 10.7704 38.8764 10.9239Z" fill="#FFA700" />
    </svg>
  </span>
)

export default titleUnderline
