import React from "react"
import TemplatePageAgile from "@components/pageAgile/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>La agilidad como parte de nuestra cultura organizacional [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/nosotros/cultura-agile/`}
      />
      <meta name="robots" content="noindex, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="La agilidad como parte de nuestra cultura organizacional"
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="La agilidad como parte de nuestra cultura organizacional"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/nosotros/cultura-agile/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="La agilidad como parte de nuestra cultura organizacional"
      />
    </Helmet>
    <TemplatePageAgile location={location} />
  </div>
)

export default IndexPage
